import { lockBG, menu} from './utils/functions.js';
import $ from 'jquery';

const mobNav = new menu();
let winHeight = 0;


const resizeHandler = ()=>{
  winHeight = $(window).height();
}

resizeHandler();

$(window).on('load resize', resizeHandler).on('scroll.prlx', (e)=>{

  let st = $(e.currentTarget).scrollTop();
  let count = 0;

  let lastThisY = 0;
  let currentSectionIndex = 1;
  let currentSectionRealIndex = 0;


  $('.prlx').each((i, elem) => {
    let thisY = $(elem).offset().top;
    let ratio =  0.8;

    if(thisY <= st + winHeight * ratio){
      $(elem).addClass('executed');

      if(thisY == lastThisY){
        count++;
      } else {
        count = 0;
      }

      lastThisY = thisY;
      currentSectionIndex = i + 1;
    }


  });


});


window.addEventListener("load", function() {
  $(window).trigger('scroll');
});